function validate() {
  const name = document.getElementById('name')
  const mail = document.getElementById('mail')
  const phone = document.getElementById('phone')
  const gdpr = document.getElementById('gdpr')

  let error = 0

  if (name.value == '') {
    document.getElementById('nameInput').classList.add('error')
    document.getElementById('nameLabel').innerHTML = 'Namn* Vänligen fyll i ert namn'
    error = error + 1
  } else {
    document.getElementById('nameInput').classList.remove('error')
    document.getElementById('nameLabel').innerHTML = 'Namn*'
  }

  if (mail.value == '') {
    document.getElementById('mailInput').classList.add('error')
    document.getElementById('mailLabel').innerHTML = 'Mail* Vänligen fyll i er e-postadress'
    error = error + 1
  } else {
    document.getElementById('mailInput').classList.remove('error')
    document.getElementById('mailLabel').innerHTML = 'Mail*'
  }

  if (phone.value == '') {
    document.getElementById('phoneInput').classList.add('error')
    document.getElementById('phoneLabel').innerHTML = 'Telefon* Vänligen fyll i ert telefonnummer'
    error = error + 1
  } else {
    document.getElementById('phoneInput').classList.remove('error')
    document.getElementById('phoneLabel').innerHTML = 'Telefon*'
  }
  if (!gdpr.checked) {
    document.getElementById('gdprLabel').classList.add('error')
    error = error + 1
  } else {
    document.getElementById('gdprLabel').classList.remove('error')
  }

  if (error === 0) {
    document.getElementById('submitMessage').classList.remove('hidden')
  }
}

const submit = document.getElementById('submit')

if (document.body.contains(submit)) {
  submit.addEventListener('click', () => {
    validate()
  })
}
