<template>
  <nav id="nav" class="fixed z-50 top-4 w-full right-0 flex justify-center">
    <div class="wrapper flex justify-end">
      <div
        class="bg-black-900 border border-white flex flex-col items-end pl-2 pr-4 py-2 rounded-[32px]"
      >
        <div class="flex flex-row items-center gap-8">
          <a :href="url" class="btn-job py-2 pl-2 pr-4 flex flex-row items-center rounded-full">
            <div
              class="circle rounded-full h-[34px] w-[34px] flex flex-col justify-center items-center"
            >
              <p class="font-bold">
                <span class="text-white">{{ jobs.length }}</span>
              </p>
            </div>
            <p class="pl-4">Lediga uppdrag just nu</p>
          </a>
          <a
            v-for="(link, i) in links"
            :key="link + i"
            :href="link.link.url"
            class="hover:underline"
            >{{ link.link.title }}</a
          >
          <div id="menu" class="hamburger" :class="{ 'is-active': isActive }" @click="showMenu()">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </div>
        <div id="subMenu" class="w-full text-right" :class="{ 'sub-open': isActive }">
          <ul class="gap-2 p-6 flex flex-col">
            <li v-for="(link, i) in mega" :key="link + i">
              <a :href="link.link.url">{{ link.link.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  props: ['links', 'mega', 'url'],
  data() {
    return {
      jobs: [],
      isActive: false
    }
  },
  created() {
    this.getJobs().then(() => {
      this.selectedLocation = this.defaultLocation
    })
  },
  methods: {
    showMenu: function () {
      this.isActive = !this.isActive
    },
    getJobs() {
      return this.fetchJobs().then((data) => {
        let relations = _.chain(data)
          .flatMap((page) => {
            return page.included
          })
          .uniqBy('id')
          .value()
        let jobs = _.flatMap(data, (page) => {
          return page.data
        })
          .map((job) => {
            if (!job.relationships.location.data || !job.relationships.department.data) {
              return false
            }
            job.location = _.find(relations, {
              id: job.relationships.location.data.id,
              type: 'locations'
            })
            job.department = _.find(relations, {
              id: job.relationships.department.data.id,
              type: 'departments'
            })
            return job
          })
          .filter((job) => job)
        this.jobs = jobs
        return Promise.resolve()
      })
    },
    fetchJobs(page = 1, data = []) {
      return axios
        .get('https://api.teamtailor.com/v1/jobs?page[size]=30&include=department,location', {
          headers: {
            Authorization: 'Token token=12vaOwuhsHRB-ZtfJfyCHt_YqlS8_A7KCeHH5K4i',
            'X-Api-Version': '20240101',
            Accept: 'application/vnd.api+json'
          }
        })
        .then((response) => {
          data.push(response.data)
          if (page < response.data.meta['page-count']) {
            return this.fetchJobs(page + 1, data)
          }
          return data
        })
    }
  }
}
</script>
