<template>
  <div class="flex flex-col">
    <ul class="w-full border-t-[0.5px] border-white">
      <li
        v-for="(job, index) in visibleCJobs"
        :key="job.id + index"
        class="w-full flex flex-row flex-wrap ml-auto py-8 border-b-[0.5px] border-white bg-black current-jobs group hover:bg-white hover:text-black px-5 md:px-6 lg:px-12"
      >
        <a target="_blank" :href="job.links['careersite-job-url']" class="w-full">
          <div class="col-span-10 flex flex-row justify-between">
            <span
              class="border-[0.5px] border-green text-green body-sm group-hover:border-black group-hover:text-black py-0.5 md:py-1.5 px-2 text-center flex flex-col justify-center"
              >Tillgänglig</span
            >
            <span class="arrow-white lg:hidden"></span>
          </div>
          <div class="w-full pt-5 grid grid-cols-10 items-center pr-5 md:pr-2 lg:pr-0">
            <h3 class="col-span-10 md:col-span-8 lg:col-span-5 heading-sm pr-8">
              {{ job.attributes.title }}
            </h3>
            <div
              class="col-span-10 lg:col-span-5 grid grid-cols-2 lg:grid-cols-5 pt-2 md:pt-0 lg:pl-4"
            >
              <p class="col-span-2 lg:pl-4">{{ job.location.attributes.city }}</p>
              <p class="col-span-2 lg:pl-2">{{ job.department.attributes.name }}</p>
              <div class="col-span-1 hidden lg:block text-end">
                <span class="arrow-white"></span>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="pt-12 flex flex-row item-center justify-center">
      <button v-if="jobsVisible < jobs.length" class="btn-green" @click="jobsVisible += step">
        Ladda fler
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  data() {
    return {
      jobs: [],
      locations: [],
      departments: [],
      selectedLocation: 'all',
      selectedDepartment: 'all',
      jobsVisible: 6,
      step: 6
    }
  },
  computed: {
    jobCategories() {
      return _.groupBy(this.filteredJobs, (job) => {
        return job.department.attributes.name
      })
    },
    visibleCJobs() {
      return this.jobs.slice(0, this.jobsVisible)
    }
  },
  created() {
    this.getJobs().then(() => {
      this.selectedLocation = this.defaultLocation
    })
  },
  methods: {
    getJobs() {
      return this.fetchJobs().then((data) => {
        let relations = _.chain(data)
          .flatMap((page) => {
            return page.included
          })
          .uniqBy('id')
          .value()
        let jobs = _.flatMap(data, (page) => {
          return page.data
        })
          .map((job) => {
            if (!job.relationships.location.data || !job.relationships.department.data) {
              return false
            }
            job.location = _.find(relations, {
              id: job.relationships.location.data.id,
              type: 'locations'
            })
            job.department = _.find(relations, {
              id: job.relationships.department.data.id,
              type: 'departments'
            })
            return job
          })
          .filter((job) => job)
        this.jobs = jobs
        this.locations = _.filter(relations, { type: 'locations' })
        this.departments = _.filter(relations, { type: 'departments' })
        return Promise.resolve()
      })
    },
    fetchJobs(page = 1, data = []) {
      return axios
        .get('https://api.teamtailor.com/v1/jobs?page[size]=30&include=department,location', {
          headers: {
            Authorization: 'Token token=12vaOwuhsHRB-ZtfJfyCHt_YqlS8_A7KCeHH5K4i',
            'X-Api-Version': '20240101',
            Accept: 'application/vnd.api+json'
          }
        })
        .then((response) => {
          data.push(response.data)
          if (page < response.data.meta['page-count']) {
            return this.fetchJobs(page + 1, data)
          }
          return data
        })
    }
  }
}
</script>
